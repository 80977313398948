import {
  Grow,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ImageCard from '../../components/ImageCard';
import Layout from '../../components/Layout';
import SeoHeader from '../../components/SeoHeader';
import insertAds from '../../utils/insertAds';
import { guides as guidesSection } from '../../utils/sections';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const layout = {
  SeoProps: {
    title: 'Guides Overview',
    description:
      'Here you can find all our general GW2 guides designed to improve your knowledge of the game.',
  },
  ContainerProps: {
    paper: false,
  },
};

const useStyles = makeStyles()((theme) => ({
  guideCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.text.primary}`,
      transform: `scale(0.99) !important`,
    },
  },
}));

function GuidesPage({
  data: {
    guides: { edges },
  },
  location,
}) {
  const { classes } = useStyles();
  const [state, setState] = React.useState({ mounted: false });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    setState({ mounted: true });
  }, []);

  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <ImageList cols={small ? 1 : 2} gap={40} rowHeight="auto">
        {insertAds(
          guidesSection.orderEdges(edges).map(
            ({
              node: {
                frontmatter: {
                  title,
                  date,
                  image: { childImageSharp },
                },
                fields: { slug },
              },
            }) => ({
              content: (
                <ImageCard
                  key={slug}
                  link={slug}
                  image={childImageSharp.gatsbyImageData}
                  title={title}
                  topLeft={dayjs().to(dayjs(date.trim()))}
                  className={classes.guideCard}
                />
              ),
            }),
          ),
          {
            style: {
              position: 'absolute',
              left: 0,
              top: 0,
              botom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            keyPrefix: `guides-`,
            format: 'auto',
            responsive: 'true',
            slot: '7724795900',
            wrap: (inner) => (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  paddingTop: '56.25%',
                }}
              >
                {inner}
              </div>
            ),
          },
        ).map(({ content, isAd }, index) => (
          <Grow
            className={isAd ? 'adsbygoogle' : ''}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            in={state.mounted}
            {...(state.mounted ? { timeout: (index + 1) * 100 } : {})}
          >
            <ImageListItem>{content}</ImageListItem>
          </Grow>
        ))}
      </ImageList>
    </Layout>
  );
}

export default GuidesPage;

export const query = graphql`
  {
    guides: allMdx(filter: { fields: { section: { eq: "guides" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
